import React from "react"

import Layout from "../../layouts/En"

import SEO from "../../components/SEO"
import Card from "../../components/Card"
import CheckPoint from "../../components/Checkpoint"

import Export from "../../icons/export.svg"
import Graph from "../../icons/graph.svg"
import Search from "../../icons/search.svg"
import Shield from "../../icons/shield.svg"

const MPowerPage = () => (
  <Layout title="M-Power">
    <SEO keywords={[`m-power`]} title="M-Power" />

    <section className="flex justify-center">
      <Card className="w-full sm:w-3/4 md:w-2/3 mb-16">
        <div className="flex mb-4">
          <div className="w-10 mr-4">
            <Graph className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Easy presentation</h3>
            <p>
              With M-Power presentation and editing of tables and graphs is fast
              and easy.
            </p>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-10 mr-4">
            <Search className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Quick searching</h3>
            <p>
              Searching for information needed for one time situation, for
              example the telephone charges for the previous year, is intuitive.
            </p>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-10 mr-4">
            <Export className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Exporting</h3>
            <p>
              Materials can be directly exported to Office reports, such as
              Word, Excel and Powerpoint.
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="w-10 mr-4">
            <Shield className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Security</h3>
            <p className="mb-0">
              Strong encryption level of the M-Power software ensures secure
              storaging of your data.
            </p>
          </div>
        </div>
      </Card>
    </section>

    <section className="flex justify-center">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-16">
          M-Power is strategic planning software, a navigator, that gives your
          company realistic income statement projections for the entire
          accounting period. M-Power creates this realistic route for the goals
          you set by using its calculation engine to tie your bookkeeping
          history to your future estimates.
        </p>
        <h3 className="mb-4">Autopilot</h3>
        <p>
          M-Power is a tool, an autopilot that helps the leadership to make
          swift decisions and prompt changes when needed. You will immediately
          see even the smallest signals and how different change options would
          affect your route and your goal.
        </p>
        <p className="mb-16">
          M-Power can be used for communication around bookkeeping, managing
          soft information, graphic simulation and searching for small signals.
          The software has a factual chart of accounts, reality analysis, key
          performance indicator editor, interim reports and prediction and
          analysis report of the annual income statement. It also offers
          reporting through MS-office.
        </p>
        <h3 className="mb-4">Business Intelligence</h3>
        <p className="mb-16">
          M-Power provides the services needed for flexible business information
          management. Its data flow, data processing and reporting is
          specifically designed for business intelligence. M-Power works
          smoothly to provide important services such as collecting data and
          creating key performance indicators.
        </p>
      </div>
    </section>

    <section className="flex justify-center">
      <Card className="w-full sm:w-3/4 md:w-2/3">
        <CheckPoint className="mb-4">
          M-Power is usable, versatile and interactive.
        </CheckPoint>
        <CheckPoint className="mb-4">
          Monitoring and comparing the budget execution is possible for several
          past and future financial years.
        </CheckPoint>
        <CheckPoint className="mb-4">
          Including facts in analyzing financial information is easy and
          efficient.
        </CheckPoint>
        <CheckPoint>
          M-Power does not tie your company to any predetermined pattern but
          instead integrates to your current needs.
        </CheckPoint>
      </Card>
    </section>
  </Layout>
)

export default MPowerPage
